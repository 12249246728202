import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import {
  ChristmasOCLogoIcon, ChristmasOCSmallLogoIcon, OCLogoIcon, OCSmallLogoIcon,
} from 'assets/icons';
import { HAS_CHRISTMAS_THEME } from 'constants/index';
import React, { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Snowfall from 'react-snowfall';
import SelectOrganization from './select-organization';
import {
  sidebar,
  sidebarItemsContainer,
  sidebarLogo,
  sidebarTopContainer,
  sidebarTrigger,
  sidebarTriggerIcon,
} from './styles';
import useSideBarItems from './useSideBarItems';

function Sidebar({ collapsed, setCollapsed }) {
  const { bottomItems, topItems } = useSideBarItems(collapsed);
  const history = useHistory();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(
    location.pathname === '/' || location.pathname === ''
      ? '/console'
      : location.pathname,
  );

  const handleGoHome = () => {
    history.push('/console');
  };

  const Logo = useMemo(() => (HAS_CHRISTMAS_THEME ? (collapsed ? ChristmasOCSmallLogoIcon : ChristmasOCLogoIcon)
    : (collapsed ? OCSmallLogoIcon : OCLogoIcon)), [collapsed]);
  const trigger = useMemo(() => {
    const Trigger = collapsed ? MenuUnfoldOutlined : MenuFoldOutlined;
    return (
      <div
        className={`flex items-center justify-${collapsed ? 'center' : 'start'} h-100`}
        style={sidebarTrigger(collapsed)}
      >
        <Trigger style={sidebarTriggerIcon} />
      </div>
    );
  }, [collapsed]);

  const onSelectMenu = (e) => {
    setCurrentPath(e.key);
  };

  return (
    <Layout.Sider
      style={sidebar}
      width={240}
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      trigger={trigger}
    >
      <div className="flex flex-column justify-content-between" style={sidebarTopContainer}>
        <div>
          <div className="flex justify-start" style={{ ...sidebarLogo, position: 'relative' }}>
            {HAS_CHRISTMAS_THEME && <Snowfall snowflakeCount={8} style={{ marginTop: -15 }} />}
            <Logo onClick={handleGoHome} />
          </div>

          <SelectOrganization collapsed={collapsed} setCollapsed={setCollapsed} />
          <Menu
            style={sidebarItemsContainer}
            selectedKeys={[currentPath]}
            onClick={onSelectMenu}
          >
            {topItems}
          </Menu>
        </div>
        <Menu
          style={sidebarItemsContainer}
          defaultSelectedKeys={['']}
          selectedKeys={[currentPath]}
        >
          {bottomItems}
        </Menu>
      </div>
    </Layout.Sider>
  );
}

export default Sidebar;
