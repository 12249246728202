import axios from 'axios';
import { API_URL } from 'constants';
import { AuthContext } from 'constants/contexts';

export function httpServiceFactory({ baseUrl, getAuthorizationFn, getUrlParamsFn }) {
  const axiosInstance = axios.create({
    baseURL: baseUrl,
  });
  axiosInstance.interceptors.request.use((config) => {
    if (getAuthorizationFn && config.headers.Authorization === undefined) {
      config.headers = {
        ...config.headers,
        Authorization: getAuthorizationFn(),
      };
    }
    if (getUrlParamsFn) {
      const params = getUrlParamsFn();
      config.url = Object.entries(params).reduce((url, [key, value]) => url.replace(`{{${key}}}`, value), config.url);
    }
    return config;
  }, (error) => Promise.reject(error));
  return axiosInstance;
}
const http = httpServiceFactory({
  baseUrl: API_URL,
  getAuthorizationFn: () => {
    const user = AuthContext.getUser();
    return user ? `Token ${user.authToken}` : '';
  },
  getUrlParamsFn: () => {
    const organization = AuthContext.getOrganization();
    return { organizationId: organization?.id };
  },
});

export function getHttpErrorMessage(error, showErrorObjectName = true) {
  const { message: errorMessage, response: { data } = {} } = error;
  if (data?.errors) {
    return formatErrors(data.errors, showErrorObjectName);
  }
  if (data?.error) {
    return data.error;
  }
  if (data?.detail) {
    return data.detail;
  }
  if (data?.meta) {
    return data.meta.join(', ');
  }
  return errorMessage;

  function formatErrors(errors, showErrorObjectName) {
    return errors.map((e) => traverse('', e));

    function traverse(parent, child) {
      if (typeof child === 'string') {
        return `${showErrorObjectName ? `${parent} > ` : ''}${child}`;
      } if (Array.isArray(child)) {
        return traverse(parent, child[0]);
      } if (typeof child === 'object') {
        const key = Object.keys(child)[0];
        return traverse(`${parent} > ${key}`, child[key]);
      }
      return parent;
    }
  }
}

export default http;
