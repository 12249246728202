export const SIC_LIST = [
  'Healthcare and Medical Services',
  'Education and Training',
  'Construction and Engineering',
  'Legal and Law Enforcement',
  'Agriculture and Food Production',
  'Financial Services',
  'Hospitality and Food Services',
  'Retail and Consumer Goods',
  'Transportation and Logistics',
  'Manufacturing and Industrial',
  'Real Estate and Housing Services',
  'Technology and Telecommunications',
  'Environmental and Utilities',
  'Arts, Entertainment, and Media',
  'Government and Public Services',
  'Non-Profit Organizations and Social Services',
  'Personal Care and Wellness',
  'Animal and Veterinary Services',
  'Automotive Services',
  'Human Resources and Recruitment',
].map(SIC => ({ value: SIC, label: SIC }));

export const COUNTRIES = [
  {
    'label': 'Canada',
    'value': 'CA',
  },
  {
    'label': 'United States',
    'value': 'US',
  },
];

const STATES = {
  'US': [
    {
      'value': 'Alaska',
      'isoCode': 'AK',
      'countryCode': 'US',
    },
    {
      'value': 'Alabama',
      'isoCode': 'AL',
      'countryCode': 'US',
    },
    {
      'value': 'Arkansas',
      'isoCode': 'AR',
      'countryCode': 'US',
    },
    {
      'value': 'American Samoa',
      'isoCode': 'AS',
      'countryCode': 'US',
    },
    {
      'value': 'Arizona',
      'isoCode': 'AZ',
      'countryCode': 'US',
    },
    {
      'value': 'California',
      'isoCode': 'CA',
      'countryCode': 'US',
    },
    {
      'value': 'Colorado',
      'isoCode': 'CO',
      'countryCode': 'US',
    },
    {
      'value': 'Connecticut',
      'isoCode': 'CT',
      'countryCode': 'US',
    },
    {
      'value': 'District of Columbia',
      'isoCode': 'DC',
      'countryCode': 'US',
    },
    {
      'value': 'Delaware',
      'isoCode': 'DE',
      'countryCode': 'US',
    },
    {
      'value': 'Florida',
      'isoCode': 'FL',
      'countryCode': 'US',
    },
    {
      'value': 'Georgia',
      'isoCode': 'GA',
      'countryCode': 'US',
    },
    {
      'value': 'Guam',
      'isoCode': 'GU',
      'countryCode': 'US',
    },
    {
      'value': 'Hawaii',
      'isoCode': 'HI',
      'countryCode': 'US',
    },
    {
      'value': 'Iowa',
      'isoCode': 'IA',
      'countryCode': 'US',
    },
    {
      'value': 'Idaho',
      'isoCode': 'ID',
      'countryCode': 'US',
    },
    {
      'value': 'Illinois',
      'isoCode': 'IL',
      'countryCode': 'US',
    },
    {
      'value': 'Indiana',
      'isoCode': 'IN',
      'countryCode': 'US',
    },
    {
      'value': 'Kansas',
      'isoCode': 'KS',
      'countryCode': 'US',
    },
    {
      'value': 'Kentucky',
      'isoCode': 'KY',
      'countryCode': 'US',
    },
    {
      'value': 'Louisiana',
      'isoCode': 'LA',
      'countryCode': 'US',
    },
    {
      'value': 'Massachusetts',
      'isoCode': 'MA',
      'countryCode': 'US',
    },
    {
      'value': 'Maryland',
      'isoCode': 'MD',
      'countryCode': 'US',
    },
    {
      'value': 'Maine',
      'isoCode': 'ME',
      'countryCode': 'US',
    },
    {
      'value': 'Michigan',
      'isoCode': 'MI',
      'countryCode': 'US',
    },
    {
      'value': 'Minnesota',
      'isoCode': 'MN',
      'countryCode': 'US',
    },
    {
      'value': 'Missouri',
      'isoCode': 'MO',
      'countryCode': 'US',
    },
    {
      'value': 'Northern Mariana Islands',
      'isoCode': 'MP',
      'countryCode': 'US',
    },
    {
      'value': 'Mississippi',
      'isoCode': 'MS',
      'countryCode': 'US',
    },
    {
      'value': 'Montana',
      'isoCode': 'MT',
      'countryCode': 'US',
    },
    {
      'value': 'North Carolina',
      'isoCode': 'NC',
      'countryCode': 'US',
    },
    {
      'value': 'North Dakota',
      'isoCode': 'ND',
      'countryCode': 'US',
    },
    {
      'value': 'Nebraska',
      'isoCode': 'NE',
      'countryCode': 'US',
    },
    {
      'value': 'New Hampshire',
      'isoCode': 'NH',
      'countryCode': 'US',
    },
    {
      'value': 'New Jersey',
      'isoCode': 'NJ',
      'countryCode': 'US',
    },
    {
      'value': 'New Mexico',
      'isoCode': 'NM',
      'countryCode': 'US',
    },
    {
      'value': 'Nevada',
      'isoCode': 'NV',
      'countryCode': 'US',
    },
    {
      'value': 'New York',
      'isoCode': 'NY',
      'countryCode': 'US',
    },
    {
      'value': 'Ohio',
      'isoCode': 'OH',
      'countryCode': 'US',
    },
    {
      'value': 'Oklahoma',
      'isoCode': 'OK',
      'countryCode': 'US',
    },
    {
      'value': 'Oregon',
      'isoCode': 'OR',
      'countryCode': 'US',
    },
    {
      'value': 'Pennsylvania',
      'isoCode': 'PA',
      'countryCode': 'US',
    },
    {
      'value': 'Puerto Rico',
      'isoCode': 'PR',
      'countryCode': 'US',
    },
    {
      'value': 'Rhode Island',
      'isoCode': 'RI',
      'countryCode': 'US',
    },
    {
      'value': 'South Carolina',
      'isoCode': 'SC',
      'countryCode': 'US',
    },
    {
      'value': 'South Dakota',
      'isoCode': 'SD',
      'countryCode': 'US',
    },
    {
      'value': 'Tennessee',
      'isoCode': 'TN',
      'countryCode': 'US',
    },
    {
      'value': 'Texas',
      'isoCode': 'TX',
      'countryCode': 'US',
    },
    {
      'value': 'United States Minor Outlying Islands',
      'isoCode': 'UM',
      'countryCode': 'US',
    },
    {
      'value': 'Johnston Atoll',
      'isoCode': 'UM-67',
      'countryCode': 'US',
    },
    {
      'value': 'Midway Atoll',
      'isoCode': 'UM-71',
      'countryCode': 'US',
    },
    {
      'value': 'Navassa Island',
      'isoCode': 'UM-76',
      'countryCode': 'US',
    },
    {
      'value': 'Wake Island',
      'isoCode': 'UM-79',
      'countryCode': 'US',
    },
    {
      'value': 'Baker Island',
      'isoCode': 'UM-81',
      'countryCode': 'US',
    },
    {
      'value': 'Howland Island',
      'isoCode': 'UM-84',
      'countryCode': 'US',
    },
    {
      'value': 'Jarvis Island',
      'isoCode': 'UM-86',
      'countryCode': 'US',
    },
    {
      'value': 'Kingman Reef',
      'isoCode': 'UM-89',
      'countryCode': 'US',
    },
    {
      'value': 'Palmyra Atoll',
      'isoCode': 'UM-95',
      'countryCode': 'US',
    },
    {
      'value': 'Utah',
      'isoCode': 'UT',
      'countryCode': 'US',
    },
    {
      'value': 'Virginia',
      'isoCode': 'VA',
      'countryCode': 'US',
    },
    {
      'value': 'United States Virgin Islands',
      'isoCode': 'VI',
      'countryCode': 'US',
    },
    {
      'value': 'Vermont',
      'isoCode': 'VT',
      'countryCode': 'US',
    },
    {
      'value': 'Washington',
      'isoCode': 'WA',
      'countryCode': 'US',
    },
    {
      'value': 'Wisconsin',
      'isoCode': 'WI',
      'countryCode': 'US',
    },
    {
      'value': 'West Virginia',
      'isoCode': 'WV',
      'countryCode': 'US',
    },
    {
      'value': 'Wyoming',
      'isoCode': 'WY',
      'countryCode': 'US',
    },
  ],
  'CA': [
    {
      'value': 'Alberta',
      'isoCode': 'AB',
      'countryCode': 'CA',
    },
    {
      'value': 'British Columbia',
      'isoCode': 'BC',
      'countryCode': 'CA',
    },
    {
      'value': 'Manitoba',
      'isoCode': 'MB',
      'countryCode': 'CA',
    },
    {
      'value': 'New Brunswick',
      'isoCode': 'NB',
      'countryCode': 'CA',
    },
    {
      'value': 'Newfoundland and Labrador',
      'isoCode': 'NL',
      'countryCode': 'CA',
    },
    {
      'value': 'Nova Scotia',
      'isoCode': 'NS',
      'countryCode': 'CA',
    },
    {
      'value': 'Northwest Territories',
      'isoCode': 'NT',
      'countryCode': 'CA',
    },
    {
      'value': 'Nunavut',
      'isoCode': 'NU',
      'countryCode': 'CA',
    },
    {
      'value': 'Ontario',
      'isoCode': 'ON',
      'countryCode': 'CA',
    },
    {
      'value': 'Prince Edward Island',
      'isoCode': 'PE',
      'countryCode': 'CA',
    },
    {
      'value': 'Quebec',
      'isoCode': 'QC',
      'countryCode': 'CA',
    },
    {
      'value': 'Saskatchewan',
      'isoCode': 'SK',
      'countryCode': 'CA',
    },
    {
      'value': 'Yukon',
      'isoCode': 'YT',
      'countryCode': 'CA',
    },
  ],
};

export const STATES_OF_COUNTRY = Object.fromEntries(
  Object.entries(STATES).map(([country, states]) => [
    country,
    states.map(state => ({
      label: state.value,
      value: state.value
    }))
  ])
);
