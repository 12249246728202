import { InboxOutlined } from '@ant-design/icons';
import { Button, Flex, Skeleton, Spin, Tag, Timeline, Typography } from 'antd';
import { OrderStatusLabel, OrderStatusTagColor } from 'constants/status';
import OrderOverlay from 'containers/OrderOverlay';
import dayjs from 'dayjs';
import useFetchedData from 'hooks/useFetchedData';
import useIsMobile from 'hooks/useIsMobile';
import React, { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

const { Text, Title } = Typography;

function Activities({ created_at }) {
  const { id } = useParams();
  const [selectedOrderId, setSelectedOrderId] = useState();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [activitiesInfo, setActivitiesInfo] = useState([]);
  const loadingRef = useRef(false);

  const handleScroll = useCallback((e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 50;
    if (bottom) {
      loadMore();
    }
  }, []);

  const transformActivities = (activities) => {
    const data = activities?.map((order) => {
      return {
        dot: <InboxOutlined style={{ fontSize: 24, color: '#00000040' }}/>,
        children: <OrderBox order={order} onOrderDetail={setSelectedOrderId}/>,
      };
    }) || [];
    setActivitiesInfo([
      ...(activitiesInfo.slice(0, activitiesInfo?.length - 1)),
      ...data,
      {
        children: <CompanyCreatedBox dateTime={created_at}/>,
      }]);
  };
  const { data, loading, mutate: mutateActivities } = useFetchedData(
    `v1/organizations/{{organizationId}}/companies/${id}/activities/?page=${page}&page_size=20`,
    ({ results: contacts, next } = {}) => {
      setHasMore(!!next);
      transformActivities(contacts);
    });

  const loadMore = () => {
    if (!loading && hasMore && !loadingRef.current) {
      loadingRef.current = true;
      setPage(prev => prev + 1);
      setTimeout(() => {
        loadingRef.current = false;
      }, 1000);
    }
  };

  return (
    <Flex vertical className={`bg-[#F5F8F7] rounded py-4 h-[100vh] relative z-50`}>
      <Title level={4} className="font-bold mb-8 px-6">
        Activities
      </Title>
      {loading && !activitiesInfo?.length && <Skeleton active/>}
      <div
        onScroll={handleScroll}
        style={{
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 120px)',
          padding: '0 32px',
          marginLeft: 8,
        }}
      >
        <Timeline
          className="company-activity mt-4"
          items={activitiesInfo}
        />
      </div>

      {loading && activitiesInfo?.length > 0 && (
        <Flex justify="center" className="mt-2">
          <Spin size="large"/>
        </Flex>
      )}

      <OrderOverlay
        orderId={selectedOrderId}
        onClose={() => {
          setSelectedOrderId(null);
        }}
        handleOrderUpdate={mutateActivities}
      />
    </Flex>
  );
}

function CompanyCreatedBox({ dateTime }) {
  return (
    <Flex
      gap={24}
      justify="flex-end"
      align="baseline"
      style={{ width: 'calc(100% + 24px)', marginTop: 18, marginLeft: -22 }}
    >
      <div style={{
        flex: 1,
        height: '20px',
        border: '2px dashed rgba(5, 5, 5, 0.06)',
        borderRadius: '0 0 0 8px',
        borderRight: 'none',
        borderTop: 'none',
      }}/>
      <Text className="font-bold">Company created!</Text>
      <Text>{dayjs(dateTime).format('MMM DD, hh:mm A')}</Text>
    </Flex>
  );
}

function OrderBox({ order, onOrderDetail }) {
  const isMobile = useIsMobile();
  const isToday = dayjs(order.created_at).diff(new Date(), 'day') === 0;
  return (
    <Flex vertical gap={4} className="ml-3 bg-white rounded py-2 px-3 w-100">
      <Flex justify="space-between" vertical={isMobile} className="w-100">
        <Flex gap={12} vertical={isMobile} align="flex-start" >
          <Text className="font-bold">Order #{order.order_number}</Text>
          <Tag color={order.state === 0 ? 'green' : OrderStatusTagColor[order.state]}>
            {order.state === 0 ? 'Created' : OrderStatusLabel[order.state]}
          </Tag>
        </Flex>
        <Text>{dayjs(order.created_at).format(isToday ? 'hh:mm A' : 'MMM DD, hh:mm A')}</Text>
      </Flex>

      <Flex vertical={isMobile} justify="space-between" gap={4} align={isMobile ? 'flex-start' : 'center'}>
        <Flex gap={12} vertical={isMobile}>
          <Flex gap={4}>
            <Text className="font-weight-light">Customer:</Text>
            <Text className="font-bold">{order.customer_name}</Text>
          </Flex>
          <Flex gap={4}>
            <Text className="font-weight-light">Total:</Text>
            <Text className="font-bold">${order.total}</Text>
          </Flex>
        </Flex>
        <Button onClick={() => onOrderDetail(order.order_id)}>
          Order details
        </Button>
      </Flex>
    </Flex>
  );
}

export default Activities;
