import { PlusOutlined } from '@ant-design/icons';
import {
  App, Button, Divider, Flex, Skeleton, Space, Typography,
} from 'antd';
import SimpleModal from 'components/SimpleModal';
import useFetchedData from 'hooks/useFetchedData';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import http, { getHttpErrorMessage } from 'services/http';
import { captureException } from 'utils/errors';
import Contact from './Contact';
import DrawerForm from './DrawerForm';

function Contacts() {
  const { id } = useParams();
  const { message } = App.useApp();
  const [selectedContact, setSelectedContact] = useState();
  const [mode, setMode] = useState('add');
  const [open, setOpen] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const { data: contacts, loading, mutate: mutateContacts } = useFetchedData(
    `v1/organizations/{{organizationId}}/companies/${id}/contacts/`,
    ({ results: contacts } = {}) => contacts,
  );

  const onAction = (action, contact) => {
    setMode(action);
    if (contact) {
      setSelectedContact(contact);
    }
    if (action !== 'delete') {
      setOpen(true);
    }
  };
  const onMakePrimary = (contactId) => {
    setActionLoading(true);
    http.put(`/v1/organizations/{{organizationId}}/companies/${id}/contacts/${contactId}/`, { is_primary: true })
      .then(() => {
        message.success('Contact updated successfully!');
        mutateContacts(undefined, { revalidate: true, populateCache: true });
      })
      .catch((e) => {
        message.error(getHttpErrorMessage(e));
        captureException(e);
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const onDeleteConfirm = () => {
    setActionLoading(true);
    http.delete(`/v1/organizations/{{organizationId}}/companies/${id}/contacts/${selectedContact.id}/`)
      .then(() => {
        message.success('Contact deleted successfully!');
        onClose(true);
      })
      .catch((e) => {
        message.error(getHttpErrorMessage(e));
        captureException(e);
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const onClose = (needRefresh) => {
    if (needRefresh) {
      mutateContacts(undefined, { revalidate: true, populateCache: true });
    }
    setMode();
    setOpen(false);
    setSelectedContact();
  };

  async function onSubmit(data) {
    if (mode === 'add') {
      await http.post(`/v1/organizations/{{organizationId}}/companies/${id}/contacts/`, data);
    } else {
      await http.put(`/v1/organizations/{{organizationId}}/companies/${id}/contacts/${selectedContact.id}/`, { role: data.role });
    }
    onClose(true);
  }

  return (
    <>
      <Flex vertical className="border border-1 border=[#0000000F] rounded mt-10 px-3 py-2">
        <Flex justify="space-between">
          <Typography.Title level={4} className="mb-0 font-bold">
            Contacts
          </Typography.Title>
          <Button onClick={() => onAction('add')} icon={<PlusOutlined />}>
            Add
          </Button>
        </Flex>
        {(loading || actionLoading) && <Skeleton className="mt-2" />}
        {(!loading && !actionLoading) && (
          <Space direction="vertical" className="mt-2" split={<Divider className="my-0" />}>
            {contacts?.map((contact) => (
              <Contact
                key={contact.id}
                contact={contact}
                onEdit={() => onAction('edit', contact)}
                onMakePrimary={onMakePrimary}
                onRemove={() => onAction('delete', contact)}
              />
            ))}
          </Space>
        )}
      </Flex>

      <DrawerForm
        data={selectedContact}
        open={open}
        onSubmit={onSubmit}
        onClose={onClose}
        onDelete={onDeleteConfirm}
      />

      <SimpleModal
        title={`Remove ${selectedContact?.customer_data.name}`}
        open={mode === 'delete'}
        setOpen={onClose}
        submitProps={{ danger: true }}
        onSubmit={onDeleteConfirm}
        confirmButtonText="Remove"
      >
        <Typography.Text>
          By confirming, this contact will be permanently removed from the Company’s contact list.
        </Typography.Text>
      </SimpleModal>
    </>
  );
}

export default Contacts;
