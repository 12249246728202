import http from 'services/http';
import { generateURLWithParams } from 'utils';

export const fetchMenus = ({ merchantId, scheduleTime, ...params }) => http.get(
  generateURLWithParams(`v1/restaurants/${merchantId}/menus/`, {
    skip_modifiers: 'True',
    active: 'True',
    scheduled_datetime: scheduleTime,
    skip_availability_check: 1,
    ...params,
  }),
)
  .then((res) => res.data);
