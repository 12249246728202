import { Button, Form, message } from 'antd';
import { OrderType, PaymentTypes } from 'containers/CreateOrder/constants';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { captureException } from 'utils/errors';
import { getHttpErrorMessage } from 'services/http';
import ConfirmOrderModal from '../ConfirmOrderModal';
import AdditionalDetails from './AdditionalDetails';
import GeneralInfo from './GeneralInfo';
import OrderItems from './OrderItems';
import PaymentInfo from './PaymentInfo';
import { creatCallInOrder, fetchCallInOrderEstimate } from './requests';
import { StyledWrapper } from './styles';

function CreateOrderForm({ data, ...props }) {
  const { order } = useContext(CallInOrderContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [showConfirmOrderModal, setShowConfirmOrderModal] = useState(false);
  const history = useHistory();
  const [estimateData, setEstimateData] = useState();

  const [form] = Form.useForm();

  const isRequiredFieldsValid = useMemo(() => order.userId
      && order.selectedMerchant?.value
      && (order.orderType === OrderType.Pickup || (order.orderType === OrderType.Delivery && order.address))
      && order.items?.length
      && (order.paymentMethod?.id || (order.paymentMethod?.type === PaymentTypes.CARD && order.cardId)), [order]);

  const handleSaveForm = () => {
    if (isRequiredFieldsValid) {
      createOrder();
    }
    form
      .validateFields()
      .then((values) => {
      })
      .catch((e) => {
        captureException(e);
      });
  };

  const handleGoToListPage = () => {
    history.push('/console/orders');
  };

  // temporary function to get a valid size
  // TODO: remove this when size issue fixed
  function getSize(item) {
    if (item.selection.size?.id) {
      if (
        item.item_types.some(
          (type) => type.size_id === item.selection.size?.id && type.price,
        )
      ) {
        return item.selection.size?.id;
      }
      console.log(new Error(`Item size mismatch! (#${item.id})`));
    }
    return 1;
  }
  const getOrderPayload = () => {
    const customPaymentMeta = {};
    if (order.paymentMethod.type == PaymentTypes.CUSTOM && order.paymentMethod?.meta) {
      for (const [key, value] of Object.entries(order.paymentMethod?.meta?.fields)) {
        customPaymentMeta[key] = form.getFieldValue(key);
      }
    }

    const orderItems = order?.items?.map((i) => ({
      item_id: i.id,
      qty: i.qty,
      size: getSize(i),
      modifiers: i.selection.modifiers.map((b) => b.id),
      modifiers_tree: i.selection.modifiersTree || {},
      modifiers_qty_map: Object.fromEntries(
        i.selection.modifiers.map((m) => [m.id, m.qty]),
      ),
      message: i.message,
    }));
    const isDelivery = order.orderType === OrderType.Delivery;
    return {
      restaurant: order.selectedMerchant.value,
      order_type: order.orderType,
      address: order.address,
      tip: isDelivery ? (order.tipPercentage === -1 ? Number(order.tip) : null) : undefined,
      tip_percentage: isDelivery ? (order.tipPercentage === -1 ? null : order.tipPercentage) : undefined,
      voucher_code: null,
      pickup_time: null,
      items: orderItems,
      user_id: order.userId,
      adjustment_amount: Number(order.adjustmentAmount),
      delivery_fee: Number(order.deliveryFee),
      payment_type: order.paymentMethod.type,
      card_id: order.cardId,
      source: 'Admin',
      scheduled_for: order.scheduledFor,
      delivery_message: order.deliveryMessage,
      custom_payment_id: order.paymentMethod.id,
      custom_payment_meta: customPaymentMeta,
    };
  };
  const getCreateOrderEstimateData = () => {
    if (isRequiredFieldsValid) {
      form.validateFields().then((values) => {
        setLoading(true);
        const estimatePayload = getOrderPayload();
        fetchCallInOrderEstimate(estimatePayload).then((res) => {
          if (res?.status === 'success') {
            setEstimateData(res?.data);
            setShowConfirmOrderModal(true);
          }
        })
          .catch((e) => {
            captureException(e);
            messageApi.open({
              type: 'error',
              content: getHttpErrorMessage(e) || 'Something went wrong during estimate order!',
            });
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  };
  const createOrder = () => {
    setLoading(true);

    const payload = getOrderPayload();
    creatCallInOrder(payload)
      .then((res) => {
        if (res?.status === 'success') {
          handleGoToListPage();
        }
      })
      .catch((e) => {
        captureException(e);
        messageApi.open({
          type: 'error',
          content: getHttpErrorMessage(e) || 'Something went wrong during create order!',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Form
      name="call-in-order"
      layout="vertical"
      onFinish={handleSaveForm}
      onFinishFailed={(fail) => {
        console.log(fail);
      }}
      form={form}
    >
      {contextHolder}
      <StyledWrapper>
        <div className="form-container">
          <GeneralInfo />
          <OrderItems />
          <PaymentInfo />
          <AdditionalDetails />
        </div>

        {/* Buttons */}
        <div className="box--bottom">
          <div className="btns-right">
            <Button
              onClick={handleGoToListPage}
              label="Cancel"
              type="neutral"
              classes="btn btn-cancel"
            />
            <Button key="cancel" onClick={handleGoToListPage} type="default">
              Cancel
            </Button>
            <Button
              key="submit"
              onClick={getCreateOrderEstimateData}
              type="primary"
              style={{ marginLeft: 10 }}
              loading={loading}
              disabled={!isRequiredFieldsValid || loading}
            >
              Submit
            </Button>
          </div>
        </div>

        <ConfirmOrderModal
          open={showConfirmOrderModal}
          onClose={setShowConfirmOrderModal}
          submitOrder={(values) => {
            handleSaveForm(values);
            setShowConfirmOrderModal(false);
          }}
          estimateData={estimateData}
        />
      </StyledWrapper>
    </Form>
  );
}

export default CreateOrderForm;
