import { EllipsisOutlined } from '@ant-design/icons';
import {
  Dropdown, Flex, Tag, Typography,
} from 'antd';
import React from 'react';

function Contact({
  contact, onEdit, onMakePrimary, onRemove,
}) {
  const formatPhoneNumber = (phone) => {
    if (!phone) {
      return '';
    }
    phone = `${phone.startsWith('+') ? '' : '+'}${phone}`;
    const regex = /([+]\d{1,4})(\d{3})(\d{3})(\d{4})/;
    const match = phone.match(regex);

    if (!match) {
      return phone;
    }

    const [, prefix, middle1, middle2, last] = match;

    return `${prefix} ${middle1} ${middle2} ${last}`;
  };
  return (
    <Flex vertical>
      <Flex justify="space-between">
        <Typography.Text className="font-bold ">
          {contact.customer_data.name}
          {contact.is_primary && <Tag className="ml-3">Primary</Tag>}
        </Typography.Text>
        <Dropdown
          placement="bottomRight"
          menu={{
            items: [
              !contact.is_primary && {
                key: '0',
                label: <a type="text" onClick={() => onMakePrimary(contact.id)}>Make it primary</a>,
              },
              {
                key: '1',
                label: <a type="text" onClick={() => onEdit(contact)}>Edit</a>,
              },
              {
                key: '2',
                label: <a type="text" onClick={() => onRemove(contact)}>Remove</a>,
              },
            ],
          }}
        >
          <EllipsisOutlined />
        </Dropdown>
      </Flex>
      <Typography.Text>{contact.role}</Typography.Text>
      <Typography.Text>{contact.customer_data.email}</Typography.Text>
      <Typography.Text>{formatPhoneNumber(contact.customer_data.phone)}</Typography.Text>
    </Flex>
  );
}

export default Contact;
